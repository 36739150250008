import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import MarkdownWrapper from '../../../../components/MarkdownWrapper';
import Layout from '../../../../components/Layout';
export const _frontmatter = {
  "title": "Add-exercise page plan",
  "path": "/knowledge/c0d3/pages",
  "date": "2022-08-08T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout title={props.pageContext.frontmatter.title} location={props.path} mdxType="Layout">
      <MarkdownWrapper mdxType="MarkdownWrapper">
        <h1 {...{
          "id": "creating-the-add-exercise-page",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h1" {...{
            "href": "#creating-the-add-exercise-page",
            "aria-label": "creating the add exercise page permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Creating the add-exercise page`}</h1>
        <h3 {...{
          "id": "pre-dev",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#pre-dev",
            "aria-label": "pre dev permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Pre-dev`}</h3>
        <p>{`Create a mini design doc that goes through the things that need to be made or thought of before creating the page.`}</p>
        <p><a parentName="p" {...{
            "href": "../docs/add_exercise_docs"
          }}>{`The mini design doc for this page`}</a></p>
        <h4 {...{
          "id": "data",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#data",
            "aria-label": "data permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Data`}</h4>
        <p>{`This page will have features like listing all the modules for a specific lesson and ultimately used to add exercises.`}</p>
        <p>{`Before coding, you need to find what data this page needs. In our case, we need to get all the lessons and each lesson's modules.`}</p>
        <p>{`We need the lessons to filter them and find the lesson that has the slug of the URL's param `}<inlineCode parentName="p">{`lessonSlug`}</inlineCode>{` value.`}</p>
        <p>{`The modules will be listed in the Dropdown menu, and later used to set the module to the exercise we want to add belongs to.`}</p>
        <p>{`Before finding the way to get the `}<inlineCode parentName="p">{`lessons`}</inlineCode>{`, we'll have to update the `}<a parentName="p" {...{
            "href": "https://github.com/garageScript/c0d3-app/blob/64f3ed6ed1fd10bc7c66f772421faff8463e8009/graphql/resolvers/lessons.ts"
          }}>{`lessons resolver`}</a>{` first to include the `}<inlineCode parentName="p">{`modules`}</inlineCode>{`:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-ts"
          }}>{`// graphql/resolvers/lessons.ts
export const lessons = () => {
  return prisma.lesson.findMany({
    include: {
      challenges: { orderBy: { order: 'asc' } },
      modules: { orderBy: { order: 'asc' } },
    },
    orderBy: {
      order: 'asc',
    },
  })
}
`}</code></pre>
        <p>{`We'll be using `}<inlineCode parentName="p">{`withGetAppQuery`}</inlineCode>{` that gets the data we need `}<inlineCode parentName="p">{`lessons`}</inlineCode>{` (and a bunch of others) and pass it to the component `}<inlineCode parentName="p">{`withGetAppQuery()(Component)`}</inlineCode>{`. In the component, we will extract the `}<inlineCode parentName="p">{`data`}</inlineCode>{` from the parameters and set its type as `}<inlineCode parentName="p">{`AppQueryProps`}</inlineCode>{`:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-tsx"
          }}>{`const Component = ({ data }: AppQueryProps) => {}

withGetAppQuery()(Component)
`}</code></pre>
        <p>{`The hook for mutation to add an exercise didn't exist (code-gen requires us to create a type defintion with `}<inlineCode parentName="p">{`gql`}</inlineCode>{` for each mutation/query in order to create a hook for it), so we had to create a file under `}<inlineCode parentName="p">{`graphql/queries`}</inlineCode>{` called `}<inlineCode parentName="p">{`addExercise.ts`}</inlineCode>{` that will have the following type definition.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-ts"
          }}>{`import { gql } from '@apollo/client'

const ADD_EXERCISE = gql\`
  mutation addExercise(
    $moduleId: Int!
    $description: String!
    $answer: String!
    $testStr: String
    $explanation: String
  ) {
    addExercise(
      moduleId: $moduleId
      description: $description
      answer: $answer
      testStr: $testStr
      explanation: $explanation
    ) {
      id
      description
      answer
      explanation
    }
  }
\`

export default ADD_EXERCISE
`}</code></pre>
        <h4 {...{
          "id": "structure",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#structure",
            "aria-label": "structure permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Structure`}</h4>
        <h5 {...{
          "id": "dropdown-menu",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h5" {...{
            "href": "#dropdown-menu",
            "aria-label": "dropdown menu permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Dropdown menu`}</h5>
        <p>{`The `}<inlineCode parentName="p">{`Dropdown`}</inlineCode>{` menu will be used to switch between the modules. The selected module will be stored in the parent state as we'll later need it when executing the `}<inlineCode parentName="p">{`createExercise`}</inlineCode>{` mutation.`}</p>
        <p>{`We need to consider the following cases before coding it:`}</p>
        <ul>
          <li parentName="ul">{`Should we display the lesson's first module or make the user set the module they want to add an exercise to?`}
            <ul parentName="li">
              <li parentName="ul">{`Make the user set the module because there's a chance the user might create an exercise for the default by accident.`}</li>
            </ul>
          </li>
          <li parentName="ul">{`What happens if the user didn't select a module?`}
            <ul parentName="li">
              <li parentName="ul">{`Show them an error that explains how they need to select a module to add an exercise to it.`}</li>
            </ul>
          </li>
        </ul>
        <p>{`We found out we already have a `}<inlineCode parentName="p">{`DropdownMenu`}</inlineCode>{` component in our components library. Instead of creating a new one, we chose to refactor and restyle it.`}</p>
        <p>{`The `}<inlineCode parentName="p">{`DropdownMenu`}</inlineCode>{` should have the following features for it to work for us:`}</p>
        <ol>
          <li parentName="ol">{`Set the select item as the active one`}</li>
          <li parentName="ol">{`When an item is clicked, run the item's callback function `}<inlineCode parentName="li">{`item.onClick`}</inlineCode>{` that will set the parent component's `}<inlineCode parentName="li">{`module`}</inlineCode>{` state with the `}<inlineCode parentName="li">{`item`}</inlineCode></li>
        </ol>
        <p>{`Before:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-tsx"
          }}>{`// DropdownMenu

export type Item = {
  title: string
  path?: string
  as?: 'a' | 'button'
  onClick?: Function
} | null

type DropDownMenuProps = {
  drop?: DropDirection
  items: Item[]
  title: string
  size?: 'sm' | 'lg' | undefined
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'none'
  //changes the underlying component CSS base class name
  //https://react-bootstrap.github.io/components/dropdowns/#api
  bsPrefix?: string
}

export const DropdownMenu: React.FC<DropDownMenuProps> = ({
  drop = 'down',
  variant = 'none',
  title,
  size,
  items,
  bsPrefix,
}) => {
  const menuItems = items.map((item: Item, itemsIndex: number) =>
    !item ? (
      <Dropdown.Divider key={itemsIndex} />
    ) : (
      <div className="text-center py-2 px-4" key={item.title}>
        <Dropdown.Item
          as={item.as || 'a'}
          key={itemsIndex}
          href={item.path}
          onClick={() => item.onClick && item.onClick(item.title)}
          bsPrefix={bsPrefix}
        >
          {item.title}
        </Dropdown.Item>
      </div>
    )
  )

  return (
    <>
      <div className="d-none d-lg-block">
        <DropdownButton
          title={title}
          variant={variant}
          size={size}
          drop={drop}
          bsPrefix={styles.title}
        >
          {menuItems}
        </DropdownButton>
      </div>
      <div className="d-lg-none">{menuItems}</div>
    </>
  )
}
`}</code></pre>
        <p>{`After:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-tsx"
          }}>{`export type Item = {
  title?: string
  name: string
  path?: string
  as?: 'a' | 'button'
  onClick?: Function
}

type DropDownMenuProps = {
  drop?: DropDirection
  items?: Item[] | null
  title?: string
  customTitle?: string
  size?: 'sm' | 'lg' | undefined
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'none'
  //changes the underlying component CSS base class name
  //https://react-bootstrap.github.io/components/dropdowns/#api
  bsPrefix?: string
}

const ChevronRight = () => <ChevronRightIcon size={17} />

export const DropdownMenu: React.FC<DropDownMenuProps> = ({
  items,
  title,
  customTitle,
}) => {
  const [activeItem, setActiveItem] = useState({
    name: title || customTitle,
  })

  return (
    <Dropdown>
      <Dropdown.Toggle bsPrefix={styles.dropdown} id="dropdown-lesson">
        {activeItem.name || 'None'}
        <ChevronRight />
      </Dropdown.Toggle>

      <Dropdown.Menu className={styles.dropdown__menu}>
        {items?.map((item, index) => (
          <Dropdown.Item
            key={\`\${item?.name}-\${index}\`}
            onClick={() => {
              item?.onClick && item.onClick(item)

              setActiveItem({
                name: item?.name,
              })
            }}
          >
            {item?.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
`}</code></pre>
        <p>{`Main differences:`}</p>
        <ul>
          <li parentName="ul">{`Remove most of the component props as they're not used in any other reference of the component`}</li>
          <li parentName="ul">{`Add a `}<inlineCode parentName="li">{`customTitle`}</inlineCode>{` to pass in a custom title (this is the exact same as `}<inlineCode parentName="li">{`title`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Refactor the component so it sets the selected item as the active one by calling `}<inlineCode parentName="li">{`item.onClick`}</inlineCode>{` callback function`}</li>
        </ul>
        <h5 {...{
          "id": "inputs",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h5" {...{
            "href": "#inputs",
            "aria-label": "inputs permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Inputs`}</h5>
        <p>{`For the inputs, we chose to use the `}<inlineCode parentName="p">{`FormCard`}</inlineCode>{` component from the components library. This component takes an array of objects. Each object represents the input's `}<inlineCode parentName="p">{`type`}</inlineCode>{`, `}<inlineCode parentName="p">{`value`}</inlineCode>{`, and `}<inlineCode parentName="p">{`title`}</inlineCode>{`. The input could be a markdown input or a regular input.`}</p>
        <p>{`It all went fine except the part of not creating an execrise when one of the inputs is invalid.`}</p>
        <p><inlineCode parentName="p">{`FormCard`}</inlineCode>{` has a submit button that we could pass to it our logic when it's clicked. This function executes what you pass without validating the inputs first.`}</p>
        <p>{`One solution we used is to run the `}<inlineCode parentName="p">{`formChange`}</inlineCode>{` helper for each input before adding the exercise. This method will validate each input and set its error message if it's invalid.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-ts"
          }}>{`const handleChange = async (value: string, propertyIndex: number) => {
  await formChange(
    value, // Input value
    propertyIndex, // Input index
    formOptions, // All inputs object
    setFormOptions, // SetState action to update the inputs
    exercisesValidation // Validation schema
  )
}

const onClick = () => {
  try {
    /*
      With how handleChange work, it'll run the exercisesValidation with each input and display its error message if it's invalid
      */
    formOptions.forEach((form, index) => {
      handleChange(form.value, index)
    })

    // ....

    // ...

    // ...
  } catch (err) {
    // ...
  }
}
`}</code></pre>
        <h5 {...{
          "id": "creating-an-exercise-submit",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h5" {...{
            "href": "#creating-an-exercise-submit",
            "aria-label": "creating an exercise submit permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Creating an exercise (submit)`}</h5>
        <p>{`After we made sure the inputs show an error message when they're invalid. We can now work on the logic of creating the exercise.`}</p>
        <p>{`The solution to show the error message for each input if it's invalid doesn't prevent the submit button from being executed when one of the inputs is empty.`}</p>
        <p>{`To solve it, we created a check that goes through each input and validate if it's not empty. If all of them are not empty, submit, else show an error.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-ts"
          }}>{`const onClick = () => {
  try {
    // ...

    const validateInputs = formOptions.every((form) => form.value)

    // ...

    // If all the inputs are not empty, add an exercise
    if (validateInputs) {
      return addExercise()
    }
  } catch (err) {
    // ...
  }
}
`}</code></pre>
      </MarkdownWrapper>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      